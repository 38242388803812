<template>
  <b-card>
    <b-card-header>
          <div class="text-primary h2">
            <feather-icon
              icon="StarIcon"
              size="25"
              class="mr-0 mr-sm-50"
              variant="primary"
            />
            <span class="d-none d-sm-inline">Información Principal</span>
          </div>
        </b-card-header>  
    <b-card-body>

      <b-row>
        <!-- Right Col: Table -->
        <b-col
          cols="12"
          xl="12"
          
        >
          <table class="mt-2 mt-xl-0 w-80">
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="BookOpenIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">{{$t(elementName+'.object.type')}}</span>
              </th>
              <td class="pb-50">
                {{ elementData.type.value }}
              </td>
            </tr>
            <tr v-if="elementData.type.key == 'NOTIFICATION'">
              <th class="pb-50">
                <feather-icon
                  icon="BookOpenIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">{{$t(elementName+'.object.step')}}</span>
              </th>
              <td class="pb-50">
                {{ elementData.step.value }}
              </td>
            </tr>
            <tr v-if="elementData.type.key == 'REMINDER'">
              <th class="pb-50">
                <feather-icon
                  icon="BookOpenIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">{{$t(elementName+'.object.reminder_type')}}</span>
              </th>
              <td class="pb-50">
                {{ elementData.reminder_type.value }}
              </td>
            </tr>
            <tr v-if="elementData.type.key == 'REMINDER'">
              <th class="pb-50">
                <feather-icon
                  icon="BookOpenIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">{{$t(elementName+'.object.reminder_time')}}</span>
              </th>
              <td class="pb-50">
                {{ elementData.reminder_time }}
              </td>
            </tr>
            <tr v-if="elementData.type.key == 'NOTIFICATION' || elementData.send_email">
              <th class="pb-50">
                <feather-icon
                  icon="BookOpenIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">{{$t(elementName+'.object.email_subject')}}</span>
              </th>
              <td class="pb-50">
                {{ elementData.email_subject }}
              </td>
            </tr>
            <tr v-if="elementData.type.key == 'NOTIFICATION' || elementData.send_email">
              <th class="pb-50">
                <feather-icon
                  icon="BookOpenIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">{{$t(elementName+'.object.email_content')}}</span>
              </th>
              <td class="pb-50">
                    <vue-editor 
                      id="email_content"
                      v-model="elementData.email_content"
                    ></vue-editor>
              </td>
            </tr>
            <tr v-if="elementData.type.key == 'NOTIFICATION' || elementData.send_whatsapp">
              <th class="pb-50">
                <feather-icon
                  icon="BookOpenIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">{{$t(elementName+'.object.whatsapp_content')}}</span>
              </th>
              <td class="pb-50">
                {{ elementData.whatsapp_content }}
              </td>
            </tr>
            <tr v-if="elementData.type.key == 'NOTIFICATION' || elementData.send_sms">
              <th class="pb-50">
                <feather-icon
                  icon="BookOpenIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">{{$t(elementName+'.object.sms_content')}}</span>
              </th>
              <td class="pb-50">
                {{ elementData.sms_content }}
              </td>
            </tr>
          </table>
        </b-col>
      </b-row>
      <b-row class="d-flex justify-content-end">
        <b-button 
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          @click="router().go(-1)"
        >
        {{$t('actions.back')}}
        </b-button>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BButton, BAvatar, BRow, BCol, BBadge, BFormGroup, BForm
} from 'bootstrap-vue'
import { VueEditor } from "vue2-editor"
import router from "@/router";

export default {
  components: {
    BCard, BButton, BRow, BCol, BAvatar, BBadge, BCardHeader, BCardBody, VueEditor, BFormGroup, BForm  
  },
  props: {
    elementData: {
      type: Object,
      required: true,
    },
    elementName: {
      type: String,
      required: true,
    }
  },
  setup() {
    return {
    }
  },
  methods: {
    router() {
      return router
    },
  }

}
</script>

<style>

</style>
